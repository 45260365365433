<template>
    <section class="component-container">
        <div class="text-block">
            <div class="block-content">
                <div v-html="md(localize(cmsBlock.text))" class="cms-content"></div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'TextBlock',

    props: [
        'cmsBlock'
    ]
}
</script>

<style lang="scss" scoped>
.component-container {
    .text-block {
        width: calc(100% - 2 * getSpacing('padding-default-horizontal', 'desktop'));
        max-width: calc($container-width - 2 * getSpacing('padding-default-horizontal', 'desktop'));
        margin: 0 auto;
        padding: 0 getSpacing('padding-default-horizontal', 'desktop');

        @include breakpoint('tablet') {
            width: calc(100% - 2 * getSpacing('padding-default-horizontal', 'tablet'));
            padding: 0 getSpacing('padding-default-horizontal', 'tablet');
        }

        @include breakpoint('mobile') {
            width: 100%;
            padding: 0 0;
        }
    }
}
</style>
